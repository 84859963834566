import {environment} from '../../environments/environment';

/**
 * Checks if a given URL matches any of the backend base URLs defined in the environment
 * @param url The URL to check
 * @returns boolean indicating if the URL matches any backend base URL
 */
export function isBackendUrl(url: string): boolean {
  return url.startsWith(environment.endpoints.backendBaseUrl) || url.startsWith(environment.endpoints.backendInternalBaseUrl);
}
